/******************** inline **********/
/***** inline form如果有`small-space` class，更新form item间距 */
/***** 多用于列表页头部的搜索 */
.ant-form-inline.small-space .ant-form-item {
  margin-right: @space;
}

/***** 仅用来显示的form */
.ant-form.view-only{
    > .ant-form-item{
        margin-bottom: 0;
    }
}