/* ABB voice */
@font-face {
  font-family: ABBvoice;
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Lt.eot');
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Lt.eot?#iefix')
      format('embedded-opentype'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Lt.woff2')
      format('woff2'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Lt.woff')
      format('woff'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_ACNSG_Lt.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: ABBvoice;
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Rg.eot');
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Rg.eot?#iefix')
      format('embedded-opentype'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Rg.woff2')
      format('woff2'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Rg.woff')
      format('woff'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_ACNSG_Rg.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: ABBvoice;
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Md.eot');
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Md.eot?#iefix')
      format('embedded-opentype'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Md.woff2')
      format('woff2'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Md.woff')
      format('woff'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_ACNSG_Md.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: ABBvoice;
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Bd.eot');
  src: url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Bd.eot?#iefix')
      format('embedded-opentype'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Bd.woff2')
      format('woff2'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_WCNSG_Bd.woff')
      format('woff'),
    url('https://commonapiblob.abb.com.cn/abbstatic/ABBvoice/CNSG/latest/ABBvoice_ACNSG_Bd.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: ABBVoice;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: bold;
}
p {
  font-weight: 300;
}
